@import "../app";
@import "../lib/slick-slider";
@import "../layout/tabs";
@import "../components/aboutSection";
@import "../components/authority";
@import "../components/ourclient";

// Hero Section
.hero-section {
    .hero-slider {
        position: relative;

        .hero-slide {
            width: 100%;
            height: 100vh;
            position: relative;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                background-color: $black-color;
                @include opacity(60%);
                width: 100%;
                height: 100vh;
            }

            .hero-bg {
                width: 100%;
                height: 100vh;
                object-fit: cover;
            }

            .middle-box {
                position: absolute;
                top: 50%;
                left: 375px;
                max-width: 653px;
                z-index: 9;
                @include transform(translateY(-50%));

                @media only screen and (max-width: $container) {
                    left: 355px;
                }

                @media only screen and (max-width: $exLarge) {
                    left: 332px;
                }

                @media only screen and (max-width: $large) {
                    left: 187px;
                }

                @media only screen and (max-width: $medium) {
                    left: 15px;
                    right: 15px;
                }
                @media only screen and (max-width: $Small) {
                    max-width: 55%;
                }
                @media only screen and (max-width: $exSmall) {
                    max-width: 80%;
                }

                .banner-heading {
                    color: $white-color;
                }
            }
        }

        .slick-arrow {
            position: absolute;
            font-size: 0;
            bottom: 10px;
            background-color: $white-color;
            width: 50px;
            height: 50px;
            @include transition(0.3s);

            @media only screen and (max-width: $large) {
                width: 40px;
                height: 40px;
            }

            @media only screen and (max-width: $Small) {
                width: 30px;
                height: 30px;
            }

            &.slick-next {
                left: 430px;
                @include transform(rotate(180deg));

                @media only screen and (max-width: $container) {
                    left: 340px;
                }

                // @media only screen and (max-width: $exLarge) {
                //     left: 275px;
                // }

                @media only screen and (max-width: $large) {
                    left: 230px;
                }

                @media only screen and (max-width: $medium) {
                    left: 60px;
                }

                @media only screen and (max-width: $Small) {
                    left: 50px;
                }
            }

            &.slick-prev {
                left: 375px;
                z-index: 1;

                @media only screen and (max-width: $container) {
                    left: 285px;
                }

                @media only screen and (max-width: $exLarge) {
                    left: 275px;
                }

                @media only screen and (max-width: $large) {
                    left: 185px;
                }

                @media only screen and (max-width: $medium) {
                    left: 15px;
                }
            }

            &:hover {
                background-color: $primary-color;

                &::before {
                    border-top: 2px solid $white-color;
                    border-left: 2px solid $white-color;
                }
            }

            &::before {
                content: "";
                position: absolute;
                border-top: 2px solid #0000006e;
                border-left: 2px solid #0000006e;
                width: 13px;
                height: 13px;
                left: 40%;
                top: 35%;
                @include transform(rotate(315deg));

                @media only screen and (max-width: $Small) {
                    width: 9px;
                    height: 9px;
                    top: 35%;
                }
            }
        }
    }
}

/** Common Slick Slider Flex Property **/
.testimonial-main {
    .slick-slider {
        @include flexbox;
        flex-direction: column;

        .slick-list {
            @include flexbox;
            flex-direction: column;

            .slick-track {
                @include flexbox;
                flex-direction: row;

                .slick-slide {
                    & > div {
                        width: 100%;
                        @include flexbox;
                        flex-direction: column;
                        flex-grow: 100;
                    }
                }
            }
        }

        &.slick-initialized {
            .slick-slide {
                @include flexbox;
                flex-direction: column;
                height: auto;
            }
        }
    }

    .slick-track {
        min-width: 100%;
    }
}

// Product Section
.product-section {
    padding: 64px 0 56px 0;

    @media only screen and (max-width: $large) {
        padding: 50px 0;
    }

    @media only screen and (max-width: $Small) {
        padding: 30px 0;
    }

    .product-box {
        .sky-blue-title {
            max-width: 181px;
            margin: auto;
            left: -10px;
            padding-left: 37px;

            &::before {
                left: -10px;
            }
        }
    }

    .row {
        @media only screen and (max-width: $exMedium) {
            margin: 0 -20px;
        }

        @media only screen and (max-width: $Small) {
            margin: 0 -15px;
        }

        @media only screen and (max-width: $exSmall) {
            margin: 0 -10px;
        }

        .grid-item {
            @media only screen and (max-width: $exMedium) {
                padding: 0 20px;
            }

            @media only screen and (max-width: $Small) {
                padding: 0 15px;
            }

            @media only screen and (max-width: $exSmall) {
                padding: 0 10px;
            }

            &.featured-box {
                margin-top: 50px;
                line-height: 0;

                @media only screen and (max-width: $Small) {
                    margin-top: 30px;
                }

                a {
                    &.grid-item-box {
                        flex-grow: 1;

                        .product-item {
                            @include box-shadow(
                                0px,
                                10px,
                                26px,
                                rgba(0, 0, 0, 0.12)
                            );
                            overflow: hidden;
                            min-height: 319px;

                            @media only screen and (max-width: $exMedium) {
                                min-height: 309px;
                            }
                        }
                    }
                }

                &:hover {
                    img {
                        @include transform(scale(1.2));
                    }
                }

                img {
                    @include transition(0.2s);
                    max-height: 319px;
                }

                h4 {
                    margin-top: 18px;
                    @include font-family($sans-bold, bold);
                    margin-bottom: 0;
                    .product-by {
                        display: block;
                        opacity: 0.5;
                        font-size: 65%;
                        font-weight: 400;
                        line-height: 1;
                        a {
                             display: inline-block;
                             margin-left: 4px;
                       }
                    }
                }
            }
        }
    }
}

// Partner Section
.partner-section {
    padding: 74px 0 60px;

    @media only screen and (max-width: $large) {
        padding: 50px 0;
    }

    @media only screen and (max-width: $Small) {
        padding: 30px 0;
    }

    .partner-box-main {
        max-width: 1438px;
        margin: 0 auto;
        padding: 0 15px;

        @media only screen and (max-width: $exMedium) {
            max-width: 1238px;
        }

        .row {
            margin: 0 -10px;
            padding-top: 15px;

            .grid-item {
                padding: 10px;

                .grid-item-box {
                    @include transition(0.2s);
                    flex-grow: 1;
                    min-height: 120px;
                    border: 1px solid $gray-e5;
                    line-height: 0;
                    @include box-shadow(0px, 3px, 16px, #0000001c);
                    overflow: hidden;
                    max-height: 122px;

                    img {
                        @include transition(0.4s);
                        max-height: 100%;
                        @include transform(scale(0.8));

                        @media only screen and (max-width: $Small) {
                            width: 120px;
                        }
                    }

                    &:hover {
                        @include box-shadow(
                            0px,
                            3px,
                            16px,
                            rgba(21, 31, 102, 0.4)
                        );

                        img {
                            @include transform(scale(1));
                        }
                    }
                }
            }
        }
    }
}

// Testimonial Section
.testimonial-main {
    padding: 70px 0 70px;

    @media only screen and (max-width: $large) {
        padding: 50px 0;
    }

    @media only screen and (max-width: $Small) {
        padding: 30px 0;
    }

    .container {
        max-width: 1670px;
        padding: 0 15px;

        @media only screen and (max-width: $medium) {
            max-width: 100%;
        }

        .testimonial-top {
            max-width: 880px;
            margin: 0 auto;

            p {
                font-size: 17px;
                color: $stone-color;
                margin-bottom: 57px;
                line-height: 1.47059;

                @media only screen and (max-width: $exSmall) {
                    margin-bottom: 30px;
                }
            }
        }

        .testimonial-slider {
            margin: 0 -20px;

            .testimonial-slide-box {
                padding: 0 20px;

                .testimonial-slide {
                    border: 2px solid $gray-da;
                    padding: 50px 47px;
                    @include border-radius(5px);
                    outline: none;

                    @media only screen and (max-width: $large) {
                        padding: 30px;
                    }

                    @media only screen and (max-width: $Small) {
                        padding: 20px;
                    }

                    .user-content-box {
                        &::before {
                            content: "\E909";
                            position: relative;
                            font-family: "icomoon";
                            font-size: 28px;
                            color: $gray-da;
                        }

                        p {
                            margin-bottom: 24px;
                            margin-top: 53px;
                            color: $stone-color;
                            font-size: 19px;
                            @include font-family($font-body, 400);
                            line-height: 27px;

                            @media only screen and (max-width: $exMedium) {
                                margin: 25px 0;
                            }

                            @media only screen and (max-width: $Small) {
                                font-size: 16px;
                            }
                        }

                        .user-info {
                            padding-top: 16px;

                            @media only screen and (max-width: $Small) {
                                flex-direction: column;
                            }

                            figure {
                                width: 100px;
                                height: 100px;
                                border-radius: 50%;
                                overflow: hidden;
                                margin-right: 40px;
                                @media only screen and (max-width: $Small) {
                                    margin-right: 0px;
                                    margin-top: 10px;
                                    text-align: center;
                                }
                            }

                            .info-box {
                                h5 {
                                    font-size: 25px;
                                    color: $title-color;
                                    margin-bottom: 8px;
                                    @include font-family($sans-bold, bold);
                                }

                                span {
                                    &.degination {
                                        color: $stone-color;
                                        font-size: 15px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            ul {
                &.slick-dots {
                    @include flexbox;
                    justify-content: center;
                    margin-top: 37px;
                    margin-bottom: 0;

                    @media only screen and (max-width: $medium) {
                        margin-top: 15px;
                    }
                }

                li {
                    cursor: pointer;
                    padding: 0 7px;
                    margin: 0;

                    &::before {
                        width: 0px;
                        height: 0px;
                    }

                    &.slick-active {
                        button {
                            @include transform(scale(1.3));
                            background-color: $gray-12;
                        }
                    }

                    button {
                        font-size: 0;
                        background-color: $stone-light-color;
                        width: 20px;
                        height: 20px;
                        border-radius: 20px;
                        @include transition(0.2s);

                        @media only screen and (max-width: $medium) {
                            width: 15px;
                            height: 15px;
                        }
                    }

                    &:only-child {
                        button {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

// services-section
.services-section {
    color: #fff;
    padding: 74px 0;

    @media only screen and (max-width: $large) {
        padding: 50px 0;
    }

    @media only screen and (max-width: $Small) {
        padding: 30px 0;
    }

    .sky-blue-title {
        margin-bottom: 13px;
    }

    h2.sub-title {
        margin-top: 0;

        @media only screen and (max-width: $large) {
            max-width: 380px;
        }

        @media only screen and (max-width: $Small) {
            max-width: 100%;
        }
    }

    .services {
        overflow: visible;
        margin: 0 -40px;
        padding-top: 30px;

        @media only screen and (max-width: $exMedium) {
            margin: 0 -30px;
        }

        @media only screen and (max-width: $medium) {
            margin: 0 -10px;
            justify-content: center;
        }

        .service-item {
            overflow: visible;
            padding: 0 40px;

            @media only screen and (max-width: $exMedium) {
                margin-bottom: 0;
                padding: 0 30px;
            }

            @media only screen and (max-width: $smMedium) {
                padding: 0 10px;
            }

            @media only screen and (max-width: $Small) {
                width: 50%;
                margin-bottom: 15px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            @media only screen and (max-width: $exSmall) {
                width: 100%;
            }

            figure {
                @include space-none;
                position: relative;
                height: 288px;
                z-index: 1;
                @include transition(0.3s);
                @include border-radius(5px);

                .service-item-image {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    height: 100%;
                    width: 100%;
                    z-index: 0;
                    overflow: hidden;
                    @include border-radius(5px);

                    &::before {
                        background: transparent
                            linear-gradient(180deg, #00000000 0%, #000000 100%)
                            0% 0% no-repeat padding-box;
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100%;
                        width: 100%;
                        content: "";
                        z-index: 1;
                    }

                    & > img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        transform: scale(1);
                        transition-duration: 0.3s;
                        transform-origin: center center;
                    }
                }

                figcaption {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    font-size: 24px;
                    line-height: 28px;
                    font-family: $sans-bold;
                    z-index: 1;
                    color: $white-color;
                    padding: 32px;

                    @media only screen and (max-width: $exMedium) {
                        padding: 20px;
                        font-size: 20px;
                    }
                }

                &:hover {
                    box-shadow: 0 0 45px $primary-sky-color;
                    transition-duration: 0.3s;

                    .service-item-image {
                        img {
                            transform: scale(1.2);
                            transition-duration: 0.3s;
                            transform-origin: center center;
                        }
                    }
                }
            }
        }
    }

    .grid-item.grid-item-width2 {
        @media only screen and (max-width: $exMedium) {
            width: 50%;
        }

        @media only screen and (max-width: $Small) {
            width: 100%;
        }
    }
}

// Tech Section
.tech-section {
    background-color: $gray-f3;
    padding: 115px 0;
    position: relative;

    @media only screen and (max-width: $exLarge) {
        padding: 70px 0;
    }

    @media only screen and (max-width: $large) {
        padding: 50px 0;
    }

    @media only screen and (max-width: $Small) {
        padding: 30px 0;
    }

    & > .img-wrap {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        width: 100%;
        background-color: $primary-color;

        & > figure {
            position: relative;

            img {
                display: block;
            }
        }

        @media screen and (max-width: $Small) {
            position: relative;
        }
    }

    .container {
        position: relative;
        z-index: 1;
    }
}
