.about-section {
    padding: 100px 0;
    @media only screen and (max-width:$container){
        padding: 70px 0;
    }
    @media only screen and (max-width:$large){
        padding: 50px 0;
    }
    @media only screen and (max-width:$exMedium){
        padding: 30px 0;
    }
    @media only screen and (max-width:$Small){
        padding: 0 0 30px
    }
    .container-small{
        @include flexbox;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        @media only screen and (max-width: $smMedium) {
            flex-direction: column;
           
        }
    }
    figure {
        max-width: 50%;
        align-items: center;
        justify-content: center;

        @media only screen and (max-width: $smMedium) {
            max-width: 100%;
        }
        @media only screen and (max-width: $medium) {
            max-width: 45%;
        }
        img{
            @media only screen and (max-width:$exMedium){
                max-width: 430px;
            }
            @media only screen and (max-width:$Small){
                max-width: 330px;
            }
        }
    }
    .about-content {
        max-width: 50%;
        padding: 65px 0 0 30px;
        flex-direction: column;

        @media only screen and (max-width: $smMedium) {
            max-width: 100%;
            padding: 30px 15px 0 15px;
        }
        @media only screen and (max-width:$Small){
            padding: 30px 0 0;
        }
        p {
            margin: 20px 0 50px 0; 
            line-height: 1.8;   
            text-align: justify; 
            @media only screen and (max-width:$large){
                margin: 0 0 30px 0;
            }
        }
    }
}
