.authority-section {
    padding: 77px 0 81px;
    background-color: $gray-f3;
    background-image: url('../../../images/autho-bg.svg');
    background-repeat: no-repeat;
    background-position: center -17%;
    background-size: 86%;

    @media only screen and (max-width:$container) {
        background-position: center -82%;
    }

    @media only screen and (max-width:$exLarge) {
        background-position: center 150px;
    }

    @media only screen and (max-width:$large) {
        padding: 50px 0;
        background-size: 100%;
        background-position: center 88px;
    }

    @media only screen and (max-width:$Small) {
        padding: 30px 0;
        background-image: none;
    }


    .container-small {
        margin: 0 auto;

        @media only screen and (max-width:$large) {
            max-width: 1100px;
        }

        @media only screen and (max-width:$exMedium) {
            max-width: 880px;
        }

        @media only screen and (max-width:$medium) {
            max-width: 610px;
        }

        @media only screen and (max-width:$Small) {
            max-width: 520px;
        }

        @media only screen and (max-width:$exSmall) {
            max-width: 350px;
        }

        .autho-bg {
            position: relative;
            // &::before{
            //         background-image: url('../../../images/autho-bg.svg');
            //         @include opacity(0.2);
            //         content: "";
            //         position: absolute;
            //         top: 44px;
            //         /* left: -62px; */
            //         background-size: 103%;
            //         background-repeat: no-repeat;
            //         width: 100%;
            //         height: 100%;

            //         @media only screen and (max-width:$medium){
            //             background-size: cover;
            //             top: 56px;
            //         }
            //         @media only screen and (max-width:$exSmall){
            //             left: -40px;
            //             top: 42px;
            //         }
            // }
        }

        .autho-slider {
            position: relative;
            line-height: 0;
            padding-top: 40px;

            @media only screen and (max-width:$exMedium) {
                padding-top: 0;
            }

            .autho-slide {
                background-color: #ffffff;
                margin: 0 12px;
                padding: 28px 30px 7px 30px;
                min-height: 260px;
                outline: none;

                @media only screen and (max-width:$Small) {
                    min-height: 221px;
                    padding: 15px;
                }

                .number {
                    font-size: 60px;
                    position: relative;
                    color: $black-color;
                    @include font-family($oswald-bold, bold);
                    z-index: 1;
                    margin-left: 14px;
                    line-height: 1.4834;

                    &::before {
                        content: '';
                        position: absolute;
                        background-color: #8FAEE6;
                        width: 30px;
                        height: 60px;
                        top: 19px;
                        left: -14px;
                        z-index: -1;
                    }
                }

                h4 {
                    margin: 28px 0 0;
                    @include font-family($oswald-bold, bold);
                    color: $stone-color;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    line-height: 1.5;

                    @media only screen and (max-width:$large) {
                        margin-top: 10px;
                    }
                }
            }

            button {
                font-size: 0;
                @include space-none;
                height: 0;
                width: 0;

                &.slick-arrow {
                    &::before {
                        content: '\e900';
                        position: absolute;
                        top: 50%;
                        right: -80px;
                        font-size: 21px;
                        font-family: "icomoon";
                        color: $stone-color;

                        @media only screen and (max-width:$exMedium) {
                            right: -60px;
                        }

                        @media only screen and (max-width:$Small) {
                            right: -30px;
                            font-size: 14px;
                        }

                        @media only screen and (max-width:$exSmall) {
                            right: -20px;
                        }
                    }

                    &:hover {
                        &::before {
                            color: $primary-color;
                        }
                    }
                }

                &.slick-prev {
                    &::before {
                        left: -80px;
                        right: auto;
                        @include transform(rotate(180deg));

                        @media only screen and (max-width:$exMedium) {
                            left: -60px;
                        }

                        // @media only screen and (max-width: $Small) {
                        //     left: -45px;
                        // }
                        @media only screen and (max-width:$Small) {
                            left: -30px;
                        }

                        @media only screen and (max-width:$exSmall) {
                            left: -20px;
                        }
                    }
                }

            }
        }
    }
}