.our-client{
    padding: 75px 0;
    @media only screen and (max-width:$large){
        padding: 50px 0;
    }
    @media only screen and (max-width:$exSmall){
        padding: 30px 0;
    }
    h2{
        margin-bottom: 40px;
        @media only screen and (max-width:$exSmall){
            margin-bottom: 20px;
        }
    }
    .row {
        margin: 0 -15px;
        .grid-item {
            padding: 15px;
            .figure {
                min-height: 100px;
                flex-grow: 1;
                border: 1px solid $footer-secondry-color;
                @include border-radius(5px);
                overflow: hidden;
                padding: 5px;
                img{
                    @include transition(0.4s);
                }
                &:hover{
                    @include box-shadow(0px, 3px, 16px, #0000001C);
                    img{
                        @include transform(scale(1.2));
                    }
                }
            }
        }
    }

}
