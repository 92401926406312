.ui-tabs {
    display: flex;
    padding: 0 100px;
    @media screen and (max-width: $large) {
        padding: 0 60px;
    }

    @media screen and (max-width: $exMedium) {
        padding: 0 0;
        width: 90%;
        margin: auto;
    }

    @media screen and (max-width: $Small) {
        padding: 30px 0 0;
    }
    @media screen and (max-width: $exSmall) {
        width: 100%;
    }

    .tab-list {
        background-color: $white-color;
        max-width: 380px;
        padding: 45px 0;

        @media only screen and (max-width: $large) {
            padding: 30px 0;
        }

        @media only screen and (max-width: $exMedium) {
            max-width: 260px;
        }

        @media screen and (max-width: $Small) {
            display: none;
        }

        .tab-list-item {
            padding: 21px 70px 22px;
            font-size: 20px;
            line-height: 30px;
            color: $stone-color;
            background: transparent;
            position: relative;
            span {
                white-space: nowrap;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
            }

            @media only screen and (max-width: $large) {
                padding: 15px 70px 15px;
            }

            @media only screen and (max-width: $exMedium) {
                padding: 10px 40px 10px 20px;
                font-size: 14px;
                line-height: 24px;
            }

            &::before {
                content: "\E900";
                font-family: "icomoon";
                position: absolute;
                width: 0;
                height: 0;
                right: 44px;
                left: auto;
                font-size: 8px;
                top: 24px;
                color: $primary-color;

                @media only screen and (max-width: $exMedium) {
                    top: 10px;
                    right: 30px;
                }
            }

            &:hover,
            &.active {
                &::after {
                    width: 0;
                    height: 0;
                    border-top: 36px solid transparent;
                    border-bottom: 36px solid transparent;
                    border-left: 20px solid $primary-light-color;
                    position: absolute;
                    content: "";
                    top: 0;
                    right: -20px;

                    @media only screen and (max-width: $large) {
                        border-top-width: 30px;
                        border-bottom-width: 30px;
                    }

                    @media only screen and (max-width: $exMedium) {
                        border-top-width: 22px;
                        border-bottom-width: 22px;
                    }
                }
            }

            &:hover {
                background: $primary-light-color;
                cursor: pointer;
            }

            &.active {
                font-family: $sans-bold;
                color: $white-color;
                background: $primary-sky-color;

                &::after {
                    border-left-color: $primary-sky-color;
                }

                &::before {
                    opacity: 0;
                }
            }
        }
    }

    .tab-content {
        padding-left: 27px;
        flex-grow: 1;

        @media screen and (max-width: 767px) {
            padding-left: 0px;
        }

        .tab-content-item {
            background-color: $white-color;

            .tab-content-item-header {
                position: relative;

                @media screen and (min-width: 768px) {
                    display: none;
                }

                @media screen and (max-width: $Small) {
                    font-size: 18px;
                    padding: 15px 10px;
                }
                @media screen and (max-width: $exSmall) {
                    font-size: 16px;
                }

                &::before {
                    content: "\E91B";
                    font-family: "icomoon";
                    position: absolute;
                    right: 0;
                    display: flex;
                    align-items: center;
                    @include transform(rotate(270deg));
                    padding: 15px 20px;
                    justify-content: center;
                    top: 0;

                    @media screen and (max-width: $Small) {
                        font-size: 14px;
                    }
                }
            }

            &.active {
                .tab-content-item-header {
                    background: $primary-sky-color;
                    color: $white-color;

                    &::before {
                        @include transform(rotate(90deg));
                        color: $white-color;
                    }
                }
            }

            figure {
                display: flex;
                flex-direction: column;
                // @media screen and (min-width: 768px) {
                //     margin-top: 15px
                // }

                .tab-item-image {
                    @include flexbox;
                    align-items: center;
                    justify-content: center;
                    min-height: 300px;
                    max-height: 320px;
                    overflow: hidden;
                    background-color: $gray-f3;
                    img {
                        max-height: inherit;
                        object-fit: cover;
                        width: 100%;
                    }
                    @media only screen and (max-width: $large) {
                        min-height: 250px;
                        max-height: 270px;
                    }

                    @media screen and (max-width: $smMedium) {
                        min-height: 150px;
                        max-height: 170px;
                        margin: 10px 0;
                    }
                }

                figcaption {
                    padding: 30px;
                    position: relative;

                    @media screen and (max-width: $medium) {
                        padding: 35px 20px 20px 20px;
                    }

                    .tab-cta {
                        display: inline-flex;
                        position: absolute;
                        right: 50px;
                        top: 0;
                        padding: 18px 34px;
                        background: $primary-color;
                        color: $white-color;
                        transform: translateY(-50%);

                        @media screen and (max-width: $medium) {
                            right: 15px;
                            font-size: 14px;
                            padding: 10px;
                        }

                        i:before {
                            color: $white-color;
                        }
                    }

                    .tab-content-list {
                        // display: grid;
                        // grid-template-columns: repeat(2, auto);
                        margin: 0;

                        @media screen and (max-width: $large) {
                            grid-template-columns: repeat(1, auto);
                        }

                        li {
                            font-family: $sans-bold;
                            color: $primary-color;
                            width: 50%;
                            padding: 0 15px;

                            @media screen and (max-width: $medium) {
                                width: 100%;
                            }

                            &:last-child {
                                margin-bottom: 0;
                            }
                            // &:nth-child(odd) {
                            //     padding-right: 70px;
                            //     @media screen and (max-width: $exMedium) {
                            //         padding-right: 62px;
                            //     }
                            //     @media screen and (max-width: $medium) {
                            //         padding-right: 0;
                            //     }
                            // }
                        }
                    }
                }
            }

            &:not(.active) {
                @media screen and (min-width: 768px) {
                    display: none;
                }

                @media screen and (max-width: $Small) {
                    figure {
                        display: none;
                    }
                }
            }

            &:not(:first-child) {
                @media screen and (max-width: $Small) {
                    margin-top: 10px;
                }
            }
        }
    }
}
